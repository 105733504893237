import React, { useEffect } from "react";
import "./App.css";

function App() {
	useEffect(() => {
		document.title = "NSBOZ Investments";
	}, []);

	return (
		<div className="App">
			<div className="company-info">Consultative Insights, Unified Investments, Diverse Ventures</div>

			<div className="dots-below">...</div>

			<img
				src="/nsboz-logo-black-transparent.png"
				alt="NSBOZ Logo"
				className="logo"
			/>

			<p className="main-text">NSBOZ</p>

			<p className="sub-text">Nexus Strategies for Broad Operational Zenith</p>

			<div className="services">
				<span className="service-item">Consulting & Strategy</span>
				<span className="divider">|</span>
				<span className="service-item">Technology & Software</span>
				<span className="divider">|</span>
				<span className="service-item">Real Estate</span>
			</div>

			<div className="mailbox">
				<div className="contact-label">Contact</div>
				<div className="contact-info">
					<i className="fa fa-envelope mail-icon"></i>
					<a
						href="mailto:info@nsboz.com"
						style={{ color: "white", textDecoration: "none" }}
					>
						info@nsboz.com
					</a>
				</div>
				<div className="locations">[UK, INDIA]</div>
			</div>
		</div>
	);
}

export default App;
